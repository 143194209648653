import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/index.css'
import App from './App'
import reportWebVitals from '@/reportWebVitals'
import {
  defaultIsDarkMode,
  persistedStateStorageKey,
} from '@/data/local-storage'
import { bodyDarkModeClass } from '@/data/classes'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://2aca8dde985d48d6915aed53509e514b@o232988.ingest.sentry.io/4503986635079680',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

if (typeof localStorage !== 'undefined' && typeof window !== 'undefined') {
  try {
    const state = JSON.parse(
      localStorage.getItem(persistedStateStorageKey) ?? '{}'
    )

    const isDarkMode = state?.state?.isDarkMode

    if (
      isDarkMode ||
      (isDarkMode === undefined && defaultIsDarkMode === true)
    ) {
      document.body.classList.add(bodyDarkModeClass)
    }
  } catch (err) {
    //
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
