import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
export const useCallbackRef = <T extends Function>(callback: T) => {
  const ref = useRef<T>(callback)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  return ref
}
