import * as React from "react"
import { SVGProps } from "react"

const Fingerprint = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.66 18.8a8.29 8.29 0 0 1-3.52-.76C.764 16.105-1.204 10.994.764 6.642c2.5-5.549 9.076-8.059 14.657-5.595 5.58 2.487 8.104 9.026 5.627 14.575a1.419 1.419 0 0 1-1.876.714 1.405 1.405 0 0 1-.718-1.865c1.853-4.145-.023-9.026-4.19-10.868-4.169-1.842-9.077.023-10.93 4.168-1.32 2.924.023 6.378 2.964 7.69 2.94 1.313 6.414-.023 7.734-2.947.764-1.727 0-3.753-1.737-4.513a3.434 3.434 0 0 0-2.616-.069A3.374 3.374 0 0 0 7.78 9.728a.966.966 0 0 0-.023.76c.093.253.278.46.532.553.51.23 1.112 0 1.344-.507.324-.714 1.157-1.036 1.875-.714.718.322 1.042 1.152.718 1.865-.857 1.911-3.126 2.786-5.07 1.934a3.855 3.855 0 0 1-2.015-2.118 3.77 3.77 0 0 1 .069-2.924c.672-1.52 1.922-2.671 3.473-3.27a6.185 6.185 0 0 1 4.793.138c3.15 1.405 4.562 5.089 3.172 8.22A8.79 8.79 0 0 1 8.66 18.8Z"
      fill="#1F1C25"
      className="fill-dark1-500 dark:fill-white"
    />
  </svg>
)

export default Fingerprint
