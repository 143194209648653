import React, { FC } from 'react'
import classNames from 'classnames'
import ReactSlider, { ReactSliderProps } from 'react-slider'
import styles from './Slider.module.css'

export const FontSlider: FC<ReactSliderProps> = ({ ...props }) => {
  // const [currentValue, setCurrentValue] = useState(props.defaultValue)

  return (
    <ReactSlider
      {...props}
      min={8}
      max={128}
      // defaultValue={currentValue}
      // value={currentValue}
      // onChange={(value, index) => {
      //   props.onChange?.(value, index)
      //   setCurrentValue(value)
      // }}
      renderThumb={(props, state) => (
        <>
          <div
            {...props}
            ref={props.ref}
            className={classNames(props.className, styles.thumb, 'relative')}
          >
            <div
              className={classNames(
                'p-1.5 text-[12px] dark:bg-white bg-black dark:text-black text-white rounded-[4px] absolute mt-2 -translate-x-1/2 left-1/2 top-full',
                styles.tooltip
              )}
            >
              {state.valueNow}
            </div>
          </div>
        </>
      )}
      className={classNames(
        props.className,
        styles.slider,
        'customSlider w-[182px] h-[22px]'
      )}
      trackClassName="customSlider-track"
      thumbClassName="customSlider-thumb"
    />
  )
}
