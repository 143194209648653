import * as React from 'react'
import { SVGProps } from 'react'

const DownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 1a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v8H1.055a.5.5 0 0 0-.387.817l7.558 9.237a1 1 0 0 0 1.548 0l7.558-9.237A.5.5 0 0 0 16.945 9H13V1Z"
      // fill="#fff"
      className="fill-dark1-500 dark:fill-white"
    />
  </svg>
)

export default DownArrow
