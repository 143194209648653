import classNames from 'classnames'
import React, { FC } from 'react'
import { useStore } from '@/store'
import {
  getBackgroundImageByFontId,
  getColorSchemeByFontId,
} from '@/store/selectors'
import { FontDescriptor } from '@/utils'
import BackgroundRemove from '../icons/BackgroundRemove'
import BackgroundImageToggle from '../icons/BackgroundImageToggle'
import BackgroundImageToggleHover from '../icons/BackgroundImageToggleHover'
import BackgroundColorToggle from '../icons/BackgroundColorToggle'
import BackgroundRemoveHover from '../icons/BackgroundRemoveHover'

export const Controls: FC<
  { font: FontDescriptor } & JSX.IntrinsicElements['div']
> = ({ font, ...props }) => {
  const actions = useStore(
    ({ cycleColorScheme, removeColorScheme, cycleBackgroundImage }) => ({
      cycleColorScheme,
      removeColorScheme,
      cycleBackgroundImage,
    })
  )

  // const isDarkMode = useStore((state) => state.isDarkMode)

  const backgroundImage = useStore((state) =>
    getBackgroundImageByFontId(state, font.id)
  )

  const backgroundColor = useStore((state) =>
    getColorSchemeByFontId(state, font.id)
  )

  const scheme = backgroundImage?.scheme ?? backgroundColor?.scheme

  // if (scape) {
  //   backgroundColorCycleIcon =
  //     scape === 'dark' ? (
  //       <BackgroundToggleLight className="w-[20px]" onClick={cycle} />
  //     ) : (
  //       <BackgroundToggleDark className="w-[20px]" onClick={cycle} />
  //     )
  // } else {
  //   backgroundColorCycleIcon = isDarkMode ? (
  //     <BackgroundToggleLight className="w-[20px]" onClick={cycle} />
  //   ) : (
  //     <BackgroundToggleDark className="w-[20px]" onClick={cycle} />
  //   )
  // }

  return (
    <div
      {...props}
      className={classNames(
        props.className,
        'controls flex  items-center gap-x-[8px]'
      )}
    >
      <button
        className="outline-none swichSvgOnHover"
        onClick={() => actions.removeColorScheme(font.id)}
      >
        <BackgroundRemove className="w-[22px]" scheme={scheme} />
        <BackgroundRemoveHover className="w-[22px]" scheme={scheme} />
      </button>
      <button onClick={() => actions.cycleColorScheme(font.id)} type="button">
        <BackgroundColorToggle scheme={scheme} />
      </button>
      <button
        onClick={() => {
          actions.cycleBackgroundImage(font.id)
        }}
        className="swichSvgOnHover"
      >
        <BackgroundImageToggle scheme={scheme} />
        <BackgroundImageToggleHover scheme={scheme} />
      </button>
    </div>
  )
}
