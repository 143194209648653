import classNames from 'classnames'
import { FC, useState } from 'react'
import { Controls } from '@/components/actions/Controls'
import { useStore } from '@/store'
import { attrs, FontDescriptor, getFontStyle } from '@/utils'
import {
  useObserverRefreshValue,
  useSeen,
} from '@/hooks/use-defer-until-observed'
import {
  getBackgroundImageByFontId,
  getColorSchemeByFontId,
} from '@/store/selectors'
import { FontName } from '@/components/font/FontName'
import styles from './LetterGridItem.module.css'
import { FontWait } from '@/components/Text'
import { config } from '@/config'
import { applyRefs } from '@/utils/apply-ref'
import { getTextClass } from '@/utils/text'
import { AnimatePresence, motion } from 'framer-motion'

export const LetterGridItem: FC<{
  font: FontDescriptor
  className?: string
}> = ({ font, className }) => {
  const fontStyles = getFontStyle(font.style)
  const letterViewFontSize = useStore((state) => state.fontSize)
  const pangram = useStore((state) => state.pangram)
  const isPangramChanged = useStore((state) => state.isPangramChanged)

  const backgroundColor = useStore((state) =>
    getColorSchemeByFontId(state, font.id)
  )

  const backgroundImage = useStore((state) =>
    getBackgroundImageByFontId(state, font.id)
  )
  const scape = backgroundColor ?? backgroundImage
  const fontFamily = config.isWindows ? font.fullName : font.postscriptName
  const { ref, value: fontSize } = useObserverRefreshValue(letterViewFontSize)
  const { ref: seenRef, seen } = useSeen()
  const bgImage = backgroundImage?.['grid-3']
  const [loaded, setLoaded] = useState<Record<string, boolean>>({})

  return (
    <div
      {...attrs(font)}
      key={font.id}
      style={{
        backgroundColor: backgroundColor?.backgroundColor,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      className={classNames(
        className,
        styles.gridItem,
        'outline-borderLight-500 dark:outline-borderDark-500 controls-activator relative'
      )}
    >
      <AnimatePresence>
        {bgImage && (
          <picture key={bgImage.src}>
            <source type="image/webp" srcSet={bgImage.srcSet} />
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: !loaded[bgImage.src] ? 0 : 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'tween', duration: 0.3 }}
              loading="lazy"
              src={bgImage.src}
              srcSet={bgImage.srcSet}
              onLoad={() =>
                setLoaded((prev) => ({
                  ...prev,
                  [bgImage.src]: true,
                }))
              }
              className="object-cover object-center left-0 top-0 w-full h-full absolute border-0 -z-10"
            />
          </picture>
        )}
      </AnimatePresence>
      <div
        ref={applyRefs(seenRef, ref)}
        className="min-h-[288px] relative px-4 py-6"
      >
        <Controls
          font={font}
          className={classNames('absolute right-3 top-3 controls')}
        />
        <FontName className={getTextClass(scape, { isOpaque: false })}>
          {font.fullName}
        </FontName>
        <div
          className={classNames(getTextClass(scape))}
          style={{
            fontFamily: config.backupFont,
            fontSize,
            fontStyle: fontStyles?.fontStyle,
            lineHeight: 1.1,
            fontWeight: (fontStyles?.fontWeight ?? '').toString(),
          }}
        >
          <FontWait
            style={{
              fontFamily: seen ? fontFamily : '',
            }}
          >
            <div className="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2">
              {isPangramChanged ? pangram.substring(0, 2) : 'Ag'}
            </div>
          </FontWait>
        </div>
      </div>
    </div>
  )
}
