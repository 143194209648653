import React, { FC, ReactNode, useEffect, useState } from 'react'
import IconLogo from '@/components/icons/Logo'
import IconGridMasonry from '@/components/icons/GridMasonry'
import IconGridSquare from '@/components/icons/GridLetter'
import IconGridSingle from '@/components/icons/GridSingle'
import IconDarkmodeOn from '@/components/icons/DarkmodeOn'
import IconDarkmodeOff from '@/components/icons/DarkmodeOff'
import { useStore } from '@/store'
import { GridOption } from '@/data/buttons'
import DownArrow from '@/components/icons/DownArrow'
import Fingerprint from '@/components/icons/Fingerprint'
import { FontSlider } from '@/components/Slider'
import { PangramSelector } from '@/fragments/Pangram'
import { Portal } from '@/components/Portal'
import { pangrams } from '@/data/text'
import shallow from 'zustand/shallow'
import { Bus, Events } from '@/events'

const viewsMap = {
  [GridOption.single]: {
    children: <IconGridSingle width={22} />,
    title: 'Change the layout view',
  },
  [GridOption.masonry]: {
    children: <IconGridMasonry />,
    title: 'Change the layout view',
  },
  [GridOption.square]: {
    children: <IconGridSquare />,
    title: 'Change the layout view',
  },
}

export const HeaderFrame: FC<{ middle?: ReactNode }> = ({ middle }) => {
  const actions = useStore(
    (state) => ({
      changeGridView: state.changeGridView,
      toggleDarkMode: state.toggleDarkMode,
      setFontSize: state.setFontSize,
      setPangram: state.setPangram,
      toggleListOrder: state.toggleListOrder,
    }),
    shallow
  )

  const fontSize = useStore((state) => state.fontSize)
  const { pangram, listOrder, gridView, isDarkMode } = useStore(
    ({ pangram, listOrder, gridView, isDarkMode }) => ({
      pangram,
      listOrder,
      gridView,
      isDarkMode,
    }),
    shallow
  )

  const onSwitchView = () => {
    actions.changeGridView()
  }

  const onDarkModeToggle = () => {
    actions.toggleDarkMode()
  }

  const selected = viewsMap[gridView]
  const buttonClass =
    'h-10 w-10 flex items-center justify-center active:bg-gray-400/40 focus:bg-gray-400/20 hover:bg-gray-400/20 rounded-[4px] outline-none'

  const isCustomText = !pangrams.includes(pangram)

  const slider = (isCustomText || gridView !== GridOption.single) && (
    <FontSlider
      className="mr-2"
      value={fontSize}
      onChange={(value) => {
        actions.setFontSize(value)
      }}
    />
  )

  return (
    <div className="h-navH">
      <div className="pl-6 pr-6 fixed z-overlay w-full bg-white dark:bg-dark2-500">
        <div>
          <div className="flex justify-between items-center h-navH relative">
            <a href="/">
              <IconLogo className="w-[42px]" />
            </a>

            {middle}

            <div className="flex gap-2 items-center">
              <div className="hidden md:block">{slider}</div>

              <button
                onClick={onDarkModeToggle}
                className={buttonClass}
                type="button"
                title="Change dark mode"
              >
                {isDarkMode ? <IconDarkmodeOn /> : <IconDarkmodeOff />}
              </button>

              <button
                className={buttonClass}
                onClick={() => actions.toggleListOrder()}
                type="button"
                title={
                  listOrder === 'random' ? `Random order` : `Alphabetical order`
                }
              >
                {listOrder === 'random' ? <Fingerprint /> : <DownArrow />}
              </button>

              <button
                onClick={onSwitchView}
                title={selected.title}
                className={buttonClass}
                type="button"
              >
                {selected.children}
              </button>
            </div>
          </div>
          <div className="md:hidden flex justify-end">
            <div className="py-2">{slider}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PangramButton: FC<{
  onClick?: (e: React.MouseEvent) => void
}> = ({ onClick }) => {
  const pangram = useStore((state) => state.pangram, shallow)

  const actions = useStore(
    (state) => ({
      setPangram: state.setPangram,
    }),
    shallow
  )

  const [isPangramSelectorVisible, setVisible] = useState(false)

  useEffect(() => {
    Bus.on(Events.OpenPangram, () => {
      setVisible(true)
    })
  }, [])

  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 hidden xl:block">
      <button
        className="text-dark1-500/20 dark:text-white/40 py-4 block font-medium"
        title="Change the text sample"
        onClick={(e) => {
          onClick?.(e)
          setVisible(true)
        }}
      >
        {pangram}
      </button>
      {isPangramSelectorVisible && (
        <Portal>
          <PangramSelector
            onRequestClose={() => setVisible(false)}
            onSelect={(p) => {
              actions.setPangram(p)
              setVisible(false)
            }}
          />
        </Portal>
      )}
    </div>
  )
}
