import React, { FC } from 'react'
import classNames from 'classnames'

export const FontName: FC<JSX.IntrinsicElements['div']> = ({
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(
        props.className,
        'text-[12px] font-[400] font-caption'
      )}
    >
      {children}
    </div>
  )
}
