import { useEffect, useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { useStore } from '@/store'
import classNames from 'classnames'

export const IntroModal = () => {
  const setExplored = useStore((state) => state.setExplored)
  const isExplored = useStore((state) => state.isExplored)
  const [isOpen, setOpen] = useState(false)
  const [stage, setStage] = useState(0)

  useEffect(() => {
    if (!isExplored) {
      setTimeout(() => {
        setOpen(true)
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Dialog.Root
        modal
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) setExplored()
          setOpen(open)
        }}
      >
        <Dialog.Portal>
          <AnimatePresence>
            {!isExplored && isOpen && (
              <Dialog.Overlay
                className="bg-black/40 fixed top-0 left-0 w-full h-full z-modal"
                forceMount
                asChild
              >
                <motion.div
                  transition={{
                    type: 'tween',
                    duration: 0.2,
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0 } }}
                  exit={{ opacity: 0, transition: { delay: 0 } }}
                />
              </Dialog.Overlay>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!isExplored && isOpen && (
              <Dialog.Content
                forceMount
                asChild
                style={{
                  boxShadow: '0 30px 20px 0 rgba(0,0,0,0.1)',
                }}
                className={classNames(
                  'outline-none max-w-[720px] w-full fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform h-[400px] xl:h-[560px] flex z-modal transition-colors overflow-hidden bg-[#F2F2F2]'
                )}
              >
                <motion.div
                  transition={{
                    type: 'tween',
                    duration: 0.2,
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.4 } }}
                  exit={{ opacity: 0, transition: { delay: 0 } }}
                >
                  <AnimatePresence>
                    {stage === 0 && (
                      <motion.img
                        key="1"
                        src="/modal-bg.png"
                        alt="list of fonts"
                        className="absolute left-0 top-0 w-full h-full object-cover object-center -z-20"
                        transition={{
                          type: 'tween',
                          duration: 0.4,
                        }}
                        initial={{ opacity: 1 }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      />
                    )}
                    {stage === 1 && (
                      <motion.img
                        key="2"
                        src="/modal-bg-2.2.svg"
                        alt="available hotkeys"
                        className="absolute left-0 top-0 w-full  object-cover object-center -z-10 mt-8 lg:mt-0"
                        transition={{
                          type: 'tween',
                          duration: 0.4,
                        }}
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      />
                    )}
                  </AnimatePresence>

                  <div className="px-5 pt-[22px] flex justify-between flex-col w-full z-10 relative">
                    <h1 className="text-[20px] leading-[24px]">
                      <div className="text-[#BDBDBD]">Welcome to</div>
                      <div>Localfonts.xyz</div>
                    </h1>
                    <div>
                      <div>
                        <p className="text-[14px] mb-10 max-w-[248px] mx-auto leading-[20px] text-center">
                          {stage === 0 &&
                            `A simple way to browse your local fonts in your
                            browser.`}
                          {stage === 1 &&
                            `Localfonts is more powerful when you learn a few keyboard shortcuts.`}
                        </p>
                      </div>

                      <button
                        onClick={() => {
                          if (stage === 0) {
                            setStage((prev) => prev + 1)
                          } else {
                            setExplored()
                            setOpen(false)
                          }
                        }}
                        type="button"
                        style={{
                          transition:
                            'max-width 350ms cubic-bezier(0.33, 1, 0.68, 1)',
                        }}
                        className={classNames(
                          'h-[32px] w-full leading-[32px] text-center block bg-black text-white/90 hover:bg-[#343434] text-[12px] outline-none mx-auto cursor-pointer mb-6',
                          {
                            'max-w-[336px]': stage === 0,
                            'max-w-[512px]': stage === 1,
                          }
                        )}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </motion.div>
              </Dialog.Content>
            )}
          </AnimatePresence>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  )
}
