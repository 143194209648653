import { useEffect } from 'react'
import { useCallbackRef } from '@/hooks/use-callback-ref'

type Handler = (key: string, e: KeyboardEvent) => void

export const useKeydown = ({ handler }: { handler: Handler }) => {
  const ref = useCallbackRef<Handler>(handler)

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      ref.current(e.key, e)
    }

    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [ref])
}
