import { Portal } from '@/components/Portal'
import { letterSectionAttribute } from '@/data/classes'
import { useRef, useState } from 'react'
import { useKeydown } from './use-key'
import { AnimatePresence, motion } from 'framer-motion'
import { alphabet } from '@/data/fonts'
import { HeaderFrame, PangramButton } from '@/components/Header/HeaderFrame'
import { useStore } from '@/store'

const sleep = (delay: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), delay)
  })
}

export const useScrollToLetter = () => {
  const [key, setKey] = useState<string | null>(null)
  const [isVisible, setVisible] = useState(false)
  const timeout = useRef<any>(null)
  const handlerTimeout = useRef<any>(null)
  const listOrder = useStore((state) => state.listOrder)

  const toggleListOrder = useStore((state) => state.toggleListOrder)

  const isPureKey = (e: KeyboardEvent) => {
    return !e.ctrlKey && !e.altKey && !e.metaKey && !e.shiftKey
  }

  const showOverlayAndScroll = (
    key: string,
    { onOverlayVisible }: { onOverlayVisible?: () => Promise<any> | void } = {}
  ) => {
    setKey(key.toUpperCase())
    setVisible(true)
    clearTimeout(handlerTimeout.current)
    clearTimeout(timeout.current)

    handlerTimeout.current = setTimeout(async () => {
      await onOverlayVisible?.()
      timeout.current = setTimeout(() => setVisible(false), 300)

      const el = document.querySelector(
        `[${letterSectionAttribute}=${key.toLowerCase()}]`
      )

      if (el) el.scrollIntoView({ block: 'start' })
    }, 100)
  }

  useKeydown({
    handler: (key, e) => {
      if (!isPureKey(e)) {
        return
      } else if (listOrder === 'random') {
        if (alphabet.includes(key.toLocaleLowerCase())) {
          showOverlayAndScroll(key, {
            onOverlayVisible: () => {
              toggleListOrder()
              return sleep(100)
            },
          })
        }
      } else if (alphabet.includes(key.toLowerCase())) {
        console.log('YUEAGH', key)
        showOverlayAndScroll(key)
      }
    },
  })

  const portal = (
    <Portal>
      <AnimatePresence>
        {isVisible && (
          <motion.div className="fixed top-0 left-0 w-full h-full bg-white dark:bg-dark2-500 z-overlay">
            <HeaderFrame middle={<PangramButton />} />
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[50vh]">
              <span className="text-dark2-500 dark:text-white">{key}</span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  )

  return { portal }
}
