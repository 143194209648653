import React, { FC, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import ScrollLock from 'react-scrolllock'
import FocusLock from 'react-focus-lock'
import { pangrams } from '@/data/text'
import { useStore } from '@/store'
import { HeaderFrame } from '@/components/Header/HeaderFrame'
import { Bus } from '@/events'

const Input = React.forwardRef((props, ref) => {
  // const Input: FC<JSX.IntrinsicElements['input']> = (props) => {
  return (
    <input
      ref={ref}
      {...props}
      className={classNames(
        props.className,
        'bg-dark1-500 dark:bg-white dark:text-dark1-500 dark:placeholder:text-dark1-500/20 text-white/70 placeholder:text-white/30 dark:text-dark1-500/20 rounded-full h-[40px] w-[400px] max-w-full pl-5 placeholder:pl-1 select-none'
      )}
      placeholder="Enter your sample text"
    />
  )
})

export const PangramSelector: FC<{
  onSelect: (text: string) => void
  onRequestClose: () => void
}> = ({ onSelect, onRequestClose }) => {
  const pangram = useStore((state) => state.pangram)
  const isCustomText = !pangrams.includes(pangram)
  const [text, setText] = useState(isCustomText ? pangram : '')
  const inputRef = useRef<HTMLInputElement>(null)

  useHotkeys('esc', () => {
    Bus.emit('pangram:selectend', true)
    onRequestClose()
  })

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])

  return (
    <FocusLock>
      <ScrollLock />

      <div className="fixed top-0 left-0 h-full w-full bg-red/20 z-overlay bg-white dark:bg-[#09090B] overflow-auto">
        <HeaderFrame
          middle={
            <div className="h-navH absolute top-0 left-0 w-full px-3">
              <Input
                ref={inputRef}
                spellCheck="false"
                autoCorrect="off"
                autoComplete="off"
                value={text}
                maxLength={60}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    Bus.emit('pangram:selectend', text === pangram)
                    onSelect(text)
                  }
                }}
                className="absolute left-1/2 right-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>
          }
        />

        <div className="top-0 relative mb-6">
          <div>
            <p className="text-[12px] py-3 text-center dark:text-white/50 text-black/50">
              Or choose a pangram below
            </p>
          </div>
          <div className="px-3 max-w-[400px] xl:max-w-[950px] mx-auto">
            <ul>
              {pangrams.map((p, index) => {
                return (
                  <motion.div
                    key={index}
                    transition={{ type: 'tween', delay: (index * 10) / 1000 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <PangramItem className="py-2.5 text-center">
                      <button
                        type="button"
                        onClick={() => {
                          Bus.emit('pangram:selectend', p === pangram)
                          onSelect(p)
                        }}
                      >
                        {p}
                      </button>
                    </PangramItem>
                  </motion.div>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </FocusLock>
  )
}

const PangramItem: FC<JSX.IntrinsicElements['div']> = ({
  children,
  ...props
}) => (
  <div
    {...props}
    className={classNames(
      props.className,
      'text-lg xl:text-[32px] text-dark1-500/30 dark:text-white/20 dark:hover:text-white hover:text-dark1-500 transition-colors font-bold'
    )}
  >
    {children}
  </div>
)
