import React, { useMemo } from 'react'
import uniqueId from 'lodash/uniqueId'
import { SVGProps } from 'react'

const BackgroundImageToggle = ({
  scheme,
  ...props
}: SVGProps<SVGSVGElement> & { scheme: 'light' | 'dark' }) => {
  const maskId = useMemo(() => `bg-image-cycle-${uniqueId()}`, [])

  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      data-scheme={scheme}
    >
      <circle
        cx={11}
        cy={11}
        r={10}
        // fill="#fff"
        // stroke="#1F1C25"
        className={
          scheme
            ? scheme === 'light'
              ? 'fill-white stroke-dark1-500'
              : 'fill-dark1-500  stroke-white'
            : 'fill-white stroke-dark1-500 dark:fill-dark1-500 dark:stroke-white'
        }
        strokeWidth={2}
      />
      <mask
        id={maskId}
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={4}
        y={4}
        width={14}
        height={14}
      >
        <circle
          cx={11}
          cy={11}
          r={6}
          // fill="#fff"
          // stroke="#1F1C25"
          className={
            scheme
              ? scheme === 'light'
                ? 'fill-white stroke-dark1-500'
                : 'fill-white stroke-dark1-500'
              : 'fill-white dark:fill-dark1-500 stroke-dark1-500 dark:stroke-white'
          }
          strokeWidth={2}
        />
      </mask>
      <g
        mask={`url(#${maskId})`}
        // fill="#1F1C25"
        className={
          scheme
            ? scheme === 'light'
              ? 'fill-dark1-500'
              : 'fill-white'
            : 'fill-dark1-500 dark:fill-white'
        }
      >
        <path opacity={0.9} d="M1 1h10v10H1z" />
        <path opacity={0.1} d="M1 11h10v10H1z" />
        <path opacity={0.6} d="M11 1h10v10H11z" />
        <path opacity={0.3} d="M11 11h10v10H11z" />
      </g>
    </svg>
  )
}

export default BackgroundImageToggle
