import { BackgroundScheme, ColorScheme } from '@/data/colors'

export const getTextClass = (
  scape?: BackgroundScheme | ColorScheme,
  { isOpaque = true }: { isOpaque?: boolean } = {}
) => {
  if (isOpaque)
    return scape
      ? scape.scheme === 'light'
        ? 'text-black'
        : 'text-white'
      : 'text-black dark:text-white'
  else
    return scape
      ? scape.scheme === 'light'
        ? 'text-black/50'
        : 'text-white/50'
      : 'text-black/50 dark:text-white/50'
}
