import * as React from 'react'
import { SVGProps } from 'react'

const IconLogo = ({
  scheme = 'dark',
  ...props
}: SVGProps<SVGSVGElement> & { scheme?: 'dark' | 'light' }) => {
  const className =
    scheme == null
      ? 'fill-black dark:fill-white'
      : scheme === 'dark'
      ? 'fill-black dark:fill-white'
      : 'fill-white dark:fill-black'

  return (
    <svg
      viewBox="0 0 43
    37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 18.238a5 5 0 0 1 1.678-3.737L10.455 6.7a2.334 2.334 0 0 1 3.101 0l8.777 7.801a5 5 0 0 1 1.678 3.737v11.428A2.334 2.334 0 0 1 21.676 32H2.334A2.334 2.334 0 0 1 0 29.666V18.238Z"
        // fill="#000"
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.032 0h3.67c.735 0 1.33.596 1.33 1.33v.34A1.33 1.33 0 0 1 40.702 3h-2.34a1.33 1.33 0 0 0-1.33 1.33v28.34c0 .734.596 1.33 1.33 1.33h2.34c.735 0 1.33.596 1.33 1.33v.34a1.33 1.33 0 0 1-1.33 1.33h-10.34a1.33 1.33 0 0 1-1.33-1.33v-.34c0-.734.596-1.33 1.33-1.33h2.34a1.33 1.33 0 0 0 1.33-1.33V4.33A1.33 1.33 0 0 0 32.702 3h-2.34a1.33 1.33 0 0 1-1.33-1.33v-.34c0-.734.596-1.33 1.33-1.33h6.67Z"
        className={className}
        // fill="#000"
      />
    </svg>
  )
}

export default IconLogo
