import { basename, extname } from 'path'

type Scheme = 'light' | 'dark'

export interface BackgroundScheme {
  id: string
  'grid-1': {
    srcSet: string
    srcSetWebp: string
    src: string
  }
  'grid-2': {
    srcSet: string
    srcSetWebp: string
    src: string
  }
  'grid-3': {
    srcSet: string
    srcSetWebp: string
    src: string
  }
  scheme?: Scheme
}

const single = [400, 600, 800, 1000, 1200, 1600, 2000, 2400, 2880, 3200]
const masonry = [400, 600, 800, 1000, 1200, 1600, 1920]
const grid = [400, 600, 800]

const gen = (pathname: string, ext: string, sizes: number[]) => {
  return {
    srcSet: sizes
      .map(
        (size) =>
          `/images/srcset-sizes/${basename(
            pathname,
            extname(pathname)
          )}/${size}w.webp ${size}w`
      )
      .join(', '),
    srcSetWebp: sizes
      .map(
        (size) =>
          `/images/srcset-sizes/${basename(
            pathname,
            extname(pathname)
          )}/${size}w.webp ${size}w`
      )
      .join(', '),
    src: `${pathname}.${ext}`,
  }
}

export const backgroundSchemeById: Record<string, BackgroundScheme> = {
  '1': {
    id: '1',
    'grid-1': gen('/images/Grid1_1', 'jpg', single),
    'grid-2': gen('/images/Grid2_1', 'jpg', masonry),
    'grid-3': gen('/images/Grid3_1', 'jpg', grid),
    scheme: 'light',
  },
  '2': {
    id: '2',
    'grid-1': gen('/images/Grid1_2', 'jpg', single),
    'grid-2': gen('/images/Grid2_2', 'jpg', masonry),
    'grid-3': gen('/images/Grid3_2', 'jpg', grid),
    scheme: 'dark',
  },
  '3': {
    id: '3',
    'grid-1': gen('/images/Grid1_3', 'jpg', single),
    'grid-2': gen('/images/Grid2_3', 'jpg', masonry),
    'grid-3': gen('/images/Grid3_3', 'jpg', grid),
    scheme: 'dark',
  },
  '4': {
    id: '4',
    'grid-1': gen('/images/Grid1_4', 'jpg', single),
    'grid-2': gen('/images/Grid2_4', 'jpg', masonry),
    'grid-3': gen('/images/Grid3_4', 'jpg', grid),
    scheme: 'light',
  },
  '5': {
    id: '5',
    'grid-1': gen('/images/Grid1_5', 'jpg', single),
    'grid-2': gen('/images/Grid2_5', 'jpg', masonry),
    'grid-3': gen('/images/Grid3_5', 'jpg', grid),
    scheme: 'dark',
  },
  '6': {
    id: '6',
    'grid-1': gen('/images/Grid1_6', 'jpg', single),
    'grid-2': gen('/images/Grid2_6', 'jpg', masonry),
    'grid-3': gen('/images/Grid3_6', 'jpg', grid),
    scheme: 'dark',
  },
}

export const backgrounds = Object.values(backgroundSchemeById)

export const colorSchemeById: Record<string, ColorScheme> = {
  '1': {
    id: '1',
    backgroundColor: '#92ffaa',
    foregroundColor: 'black',
    scheme: 'light',
  },
  '2': {
    id: '2',
    backgroundColor: '#ffd600',
    foregroundColor: 'black',
    scheme: 'light',
  },
  '3': {
    id: '3',
    backgroundColor: '#a6e0ed',
    foregroundColor: 'black',
    scheme: 'light',
  },
  '4': {
    id: '4',
    backgroundColor: '#141414',
    foregroundColor: 'white',
    scheme: 'dark',
  },
  '5': {
    id: '5',
    backgroundColor: '#505050',
    foregroundColor: 'white',
    scheme: 'dark',
  },
  '6': {
    id: '6',
    backgroundColor: '#FF3A3A',
    foregroundColor: 'white',
    scheme: 'dark',
  },
  '7': {
    id: '7',
    backgroundColor: '#DF9AFF',
    foregroundColor: 'white',
    scheme: 'dark',
  },
  '8': {
    id: '8',
    backgroundColor: '#F0F0F0',
    foregroundColor: 'black',
    scheme: 'light',
  },
  '9': {
    id: '9',
    backgroundColor: '#A6EFFF',
    foregroundColor: 'black',
    scheme: 'light',
  },
}

export interface ColorScheme {
  id: string
  backgroundColor: string
  foregroundColor: string
  scheme: Scheme
}

export const colors: ColorScheme[] = Object.values(colorSchemeById)
