import React from 'react'
import { SVGProps } from 'react'

const BackgroundRemoveHover = ({
  scheme,
  ...props
}: SVGProps<SVGSVGElement> & { scheme?: 'dark' | 'light' }) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-meme={scheme}
      {...props}
    >
      <circle
        cx={11}
        cy={11}
        r={10}
        // fill="#1F1C25"
        // stroke="#1F1C25"
        strokeWidth={2}
        className={
          scheme
            ? scheme === 'light'
              ? `stroke-dark1-500 fill-dark1-500 `
              : `stroke-white fill-white 500`
            : `stroke-dark1-500 fill-dark1-500 dark:stroke-white dark:fill-white`
        }
      />
      <path
        d="m8 14 6-6M8 8l6 6"
        // stroke="#fff"
        strokeWidth={2}
        className={
          scheme
            ? scheme === 'light'
              ? 'stroke-white'
              : 'stroke-dark1-500'
            : 'stroke-white dark:stroke-dark1-500'
        }
      />
    </svg>
  )
}

export default BackgroundRemoveHover
