export const pangrams = [
  'Five quacking zephyrs jolt my wax bed.',
  'The quick brown fox jumps over the lazy dog.',
  'Pack my box with five dozen liquor jugs.',
  'When zombies arrive, quickly fax Judge Pat.',
  'Cada vez que trabajo, Félix me paga un whisky.',
  'Portez ce vieux whisky au juge blond qui fume.',
  'Sylvia wagt quick den Jux bei Pforzheim.',
  'Jove xef, porti whisky amb quinze glaçons d’hidrogen, coi.',
  'דג סקרן שט בים מאוכזב ולפתע מצא חברה ',
  'Quel fez sghembo copre davanti.',
  '키스의 고유조건은 입술끼리 만나야 하고 특별한 기술은 필요치 않다.',
  'Pójdźże, kiń tę chmurność w głąb flaszy!',
  'Blitz prende ex-vesgo com cheque fajuto.',
  'Fin džip, gluh jež i čvrst konjić dođoše bez moljca.',
  'Yxskaftbud, ge vår WC-zonmö IQ-hjälp.',
  'Чуєш їх, доцю, га? Кумедна ж ти, прощайся без ґольфів!',
  'Příliš (příšerně) žluťoučký kůň úpěl ďábelské ódy.',
]
