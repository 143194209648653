import create from 'zustand'
import produce from 'immer'
import { defaultGridView, GridOption, gridViewList } from '@/data/buttons'
import { devtools, persist } from 'zustand/middleware'
import { backgrounds, colors } from '@/data/colors'
import { increment } from '@/utils/number'
import {
  defaultIsDarkMode,
  persistedStateStorageKey,
} from '@/data/local-storage'
import { bodyDarkModeClass } from '@/data/classes'
import { defaultFontSize } from '@/data/fonts'
import {
  FontDescriptor,
  OrganizedFonts,
  organizeFonts,
  preloadImage,
} from '@/utils'
import clamp from 'lodash/clamp'
import random from 'lodash/random'
import { generateRandomOrder } from './utils'
import { pangrams } from '@/data/text'

const pangram = pangrams[1]

interface FontState {
  id: string
  colorSchemeId?: string
  backgroundSchemeId?: string
}

export interface State {
  gridView: GridOption
  letterViewFontSize: number
  singleLineViewFontSize: number
  masonryViewFontSize: number
  pangram: string
  isDarkMode: boolean
  fontStatuses: Record<string, FontState | undefined>
  listOrder: 'random' | 'alphabetical'
  fontSize: number
  isPangramChanged: boolean
  setFontSize: (value: number) => void
  increaseFontSize: () => void
  decreaseFontSize: () => void
  toggleDarkMode: () => void
  changeGridView: () => void
  cycleColorScheme: (fontId: string) => void
  removeColorScheme: (id: string) => void
  setPangram: (text: string) => void
  setInitialColors: () => void
  haveFontsLoaded: boolean
  markFontsLoaded: () => void
  toggleListOrder: () => void
  fonts: OrganizedFonts
  setFonts: (fonts: FontDescriptor[]) => void
  fontsRandomized: number[]
  generateRandomFontList: () => void
  cycleBackgroundImage: (fontId: string) => void
  isExplored: boolean
  setExplored: () => void
}

export const useStore = create<
  State,
  [['zustand/devtools', never], ['zustand/persist', State]]
>(
  devtools(
    persist(
      (set, get) => {
        const generateRandomFontList = (): void => {
          const { fonts } = get()

          set(
            produce((state: State) => {
              state.fontsRandomized = generateRandomOrder(fonts.all)
            })
          )
        }

        const toggleListOrder = (): void => {
          set(
            produce((state: State) => {
              if (state.listOrder === 'alphabetical') {
                state.listOrder = 'random'
                generateRandomFontList()
              } else {
                state.listOrder = 'alphabetical'
              }
            })
          )

          window.scrollTo({ top: 0 })
        }

        const setInitialColors = () => {
          const fonts = Object.values(get().fonts.byFamily).map(
            (family) => family.representative
          )

          set(
            produce((state: State) => {
              const skip = 6

              for (let i = 0; i < fonts.length; i += skip) {
                const rand = random(i, i + skip / 2)
                const font = fonts[rand]

                if (!font) continue

                const assignRandomColor = () => {
                  const status = state.fontStatuses[font.id] ?? {
                    id: font.id,
                  }

                  if (i % 18 === 0) {
                    status.backgroundSchemeId =
                      backgrounds[random(0, backgrounds.length - 1)].id
                  } else {
                    status.colorSchemeId =
                      colors[random(0, colors.length - 1)].id
                  }
                  state.fontStatuses[font.id] = status
                }

                assignRandomColor()
              }
            })
          )
        }

        const markFontsLoaded = () => {
          set(
            produce((state: State) => {
              state.haveFontsLoaded = true
            })
          )
        }

        const changeGridView = () => {
          set(
            produce((state: State) => {
              let newIndex = gridViewList.indexOf(state.gridView) + 1

              if (newIndex > gridViewList.length - 1) newIndex = 0

              state.gridView = gridViewList[newIndex]

              return state
            })
          )
        }

        const cycleBackgroundImage = (fontId: string) => {
          set(
            produce((state: State) => {
              const font = state.fontStatuses[fontId] ?? { id: fontId }

              const index =
                backgrounds.findIndex(
                  (scheme) => scheme.id === font.backgroundSchemeId
                ) ?? 0

              const newBg =
                backgrounds[increment(index, backgrounds.length - 1)]

              // const nextImage =
              //   backgrounds[increment(index + 1, backgrounds.length - 1)]

              // backgroundImageId = newBg?.id

              // const items = [
              //   nextImage['grid-1'],
              //   nextImage['grid-2'],
              //   nextImage['grid-3'],
              // ]

              // items.forEach(({ src, srcSet, srcSetWebp }) => {
              //   preloadImage(src, srcSet, [
              //     { type: 'image/webp', srcSet: srcSetWebp },
              //   ]).then(({ currentSrc }) => {
              //     console.log('done', newBg.id, nextImage.id, currentSrc)
              //   })
              // })

              state.fontStatuses[fontId] = {
                ...font,
                colorSchemeId: undefined,
                backgroundSchemeId: newBg?.id,
              }

              return state
            })
          )
        }

        const cycleColorScheme = (fontId: string): void => {
          set(
            produce((state: State) => {
              const font = state.fontStatuses[fontId] ?? { id: fontId }

              const index =
                colors.findIndex(
                  (scheme) => scheme.id === font.colorSchemeId
                ) ?? 0

              const newColorScheme = colors[increment(index, colors.length - 1)]

              state.fontStatuses[fontId] = {
                ...font,
                colorSchemeId: newColorScheme.id,
                backgroundSchemeId: undefined,
              }

              return state
            })
          )
        }

        const removeColorScheme = (id: string): void => {
          set(
            produce((state: State) => {
              const font = state.fontStatuses[id] ?? { id }
              font.colorSchemeId = undefined
              font.backgroundSchemeId = undefined
            })
          )
        }

        const toggleDarkMode = () => {
          set(
            produce((state: State) => {
              state.isDarkMode = !state.isDarkMode

              if (state.isDarkMode)
                document.body.classList.add(bodyDarkModeClass)
              else document.body.classList.remove(bodyDarkModeClass)

              return state
            })
          )
        }

        const setPangram = (text: string) => {
          set(
            produce((state: State) => {
              state.isPangramChanged = true
              state.pangram = text
            })
          )
        }

        const setFontSize = (value: number) => {
          set(
            produce((state: State) => {
              state.fontSize = clamp(value, 0, 128)
            })
          )
        }

        const state: State = {
          changeGridView,
          cycleColorScheme,
          removeColorScheme,
          toggleDarkMode,
          setFontSize,
          fontSize: defaultFontSize,
          increaseFontSize: () => {
            const value = get().fontSize
            set(
              produce((state: State) => {
                state.fontSize = clamp(value + 5, 0, 128)
              })
            )
          },
          decreaseFontSize: () => {
            const value = get().fontSize
            set(
              produce((state: State) => {
                state.fontSize = clamp(value - 5, 0, 128)
              })
            )
          },
          isExplored: false,
          listOrder: 'alphabetical',
          isDarkMode: defaultIsDarkMode,
          gridView: defaultGridView,
          letterViewFontSize: 84,
          singleLineViewFontSize: 84,
          masonryViewFontSize: 64,
          pangram,
          setPangram,
          isPangramChanged: false,
          fontStatuses: {},
          setInitialColors,
          haveFontsLoaded: false,
          markFontsLoaded,
          toggleListOrder,
          fontsRandomized: [],
          fonts: {
            all: [],
            byFamily: {},
          },
          cycleBackgroundImage,
          generateRandomFontList,
          setExplored: () => {
            set(
              produce((state: State) => {
                state.isExplored = true

                return state
              })
            )
          },
          setFonts: (fonts: FontDescriptor[]) => {
            set(
              produce((state: State) => {
                state.fonts = organizeFonts(fonts)

                return state
              })
            )
          },
        }

        return state
      },
      {
        name: persistedStateStorageKey,
        partialize: ({ fonts: _1, haveFontsLoaded: _2, ...state }) =>
          state as any,
      }
    )
  )
)
