import * as React from 'react'
import { SVGProps } from 'react'

const DarkmodeOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={11} cy={11} r={10} stroke="#fff" strokeWidth={2} />
    <path
      d="M18.778 3.222c-4.295-4.296-11.26-4.296-15.556 0-4.296 4.296-4.296 11.26 0 15.556L18.778 3.222Z"
      fill="#fff"
    />
  </svg>
)

export default DarkmodeOn
