import React, { FC, useEffect, useState } from 'react'
import * as RadishPortal from '@radix-ui/react-portal'

export const Portal: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [isMounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  if (!isMounted) return null

  return <RadishPortal.Root>{children} </RadishPortal.Root>
}
