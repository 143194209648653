import React, { FC, useState } from 'react'
import { HTMLMotionProps, motion } from 'framer-motion'

export const LazyMotionImage: FC<HTMLMotionProps<'img'>> = ({
  onLoad,
  onError,
  ...props
}) => {
  const [isLoaded, setLoaded] = useState(false)

  return (
    <motion.img
      {...props}
      style={{ opacity: isLoaded ? 1 : 0, transition: '100ms opacity' }}
      onLoad={(e) => {
        setLoaded(true)
        onLoad?.(e)
      }}
      onError={(e) => {
        onError?.(e)
      }}
    />
  )
}

export const LazyImage: FC<JSX.IntrinsicElements['img']> = ({ ...props }) => {
  const [isLoaded, setLoaded] = useState(false)

  return (
    <img
      {...props}
      onLoad={(e) => {
        setLoaded(true)
        props?.onLoad?.(e)
      }}
      style={{
        ...props.style,
        opacity: isLoaded ? 1 : 0,
        transition: '200ms opacity',
      }}
    />
  )
}
