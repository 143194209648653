import classNames from 'classnames'
import { FC } from 'react'

export const ViewMore: FC<JSX.IntrinsicElements['button']> = ({
  children,
  ...props
}) => {
  const colors = `dark:bg-[#1c1c1e] dark:text-white dark:hover:bg-white dark:hover:text-dark2-500 hover:text-white hover:bg-dark2-500 bg-black/10 text-dark2-500`

  return (
    <button
      {...props}
      className={classNames(
        colors,
        'text-[12px] min-w-[140px] h-[28px] transition-colors rounded-full select-none outline-none'
      )}
    >
      {children}
    </button>
  )
}
