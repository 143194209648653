import React, { FC, ReactNode, useMemo, useState } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Controls } from '@/components/actions/Controls'
import { Letter } from '@/components/common'
import { FontName } from '@/components/font/FontName'
import { forceDarkModeClass, letterSectionAttribute } from '@/data/classes'
import { useStore } from '@/store'
import {
  attrs,
  FontDescriptor,
  getFontStyle,
  stack,
  toAlphabetic,
  toColumnizedAlphabetic,
} from '@/utils'
import {
  useObserverRefreshValue,
  useSeen,
} from '@/hooks/use-defer-until-observed'
import styles from './MasonryViewItem.module.css'
import { ViewMore } from '@/components/actions/ViewMore'
import { useScrollToLetter } from '@/hooks/use-scroll-to-letter'
import {
  getBackgroundImageByFontId,
  getColorSchemeByFontId,
} from '@/store/selectors'
import { FontWait } from '@/components/Text'
import { usePageHotkey } from '@/hooks/use-page-hotkey'
import { config } from '@/config'
import { applyRefs } from '@/utils/apply-ref'
import { getTextClass } from '@/utils/text'

export const MasonryViewLayout: FC = () => {
  const { fonts, listOrder, fontsRandomized } = useStore(
    ({ fonts, listOrder, fontsRandomized }) => ({
      fonts,
      listOrder,
      fontsRandomized,
    })
  )

  const { portal } = useScrollToLetter()

  const representatives = useMemo(() => {
    let result

    if (listOrder === 'random') {
      result = fontsRandomized
        .map((index) => fonts.all[index])
        .filter((font) => {
          return fonts.byFamily[font.family].representative.id === font.id
        })
    } else {
      result = Object.values(fonts.byFamily).map(
        (family) => family.representative
      )
    }

    return result
  }, [listOrder, fonts, fontsRandomized])

  const columnized = stack(2, representatives)

  const alphabetic = useMemo(
    () => toAlphabetic(representatives),
    [representatives]
  )

  const columnizedAlphabetic = useMemo(
    () => toColumnizedAlphabetic(alphabetic),
    [alphabetic]
  )

  usePageHotkey()

  let content: ReactNode

  if (listOrder === 'random') {
    content = (
      <div>
        <Letter className="pb-4 pt-3 px-6 left-0 bg-white dark:bg-dark2-500 z-30">
          Random order
        </Letter>

        <div className="px-6">
          <MasonryGrid>
            {columnized.map((fonts, i) => {
              return <MasonryColumn key={i} fonts={fonts} />
            })}
          </MasonryGrid>
        </div>
      </div>
    )
  } else {
    content = (
      <div>
        {Object.entries(columnizedAlphabetic).map(([letter, columns]) => {
          if (columns.every((column) => column.length === 0)) return null
          return (
            <div key={letter}>
              <div
                {...{ [letterSectionAttribute]: letter.toLowerCase() }}
                className="-top-navH relative h-[1px]"
              />
              <Letter className="pb-4 pt-3 px-6 sticky w-full top-navH left-0 bg-white dark:bg-dark2-500 z-30">
                <div>{letter.toUpperCase()}</div>
              </Letter>
              <div className="px-6">
                <MasonryGrid>
                  {columns.map((fonts, i) => {
                    return <MasonryColumn key={i} fonts={fonts} />
                  })}
                </MasonryGrid>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      {portal}
      {content}
    </div>
  )
}

const MasonryGrid: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div className="lg:flex last:mb-12">{children}</div>
}

const MasonryColumn: FC<{ fonts: FontDescriptor[] }> = ({ fonts }) => {
  return (
    <div
      className={classNames(
        styles.masonryColumn,
        'border-borderLight-500 dark:border-borderDark-500'
      )}
    >
      {fonts.map((font) => {
        return <MasonryViewItem key={font.id} font={font} />
      })}
    </div>
  )
}
export const MasonryViewItem: FC<{ font: FontDescriptor }> = ({ font }) => {
  const masonryViewFontSize = useStore((state) => state.fontSize)
  const pangram = useStore((state) => state.pangram)
  const fonts = useStore((state) => state.fonts)
  const fontStyles = getFontStyle(font.style)

  const backgroundColor = useStore((state) =>
    getColorSchemeByFontId(state, font.id)
  )

  const backgroundImage = useStore((state) =>
    getBackgroundImageByFontId(state, font.id)
  )

  const fontFamily = config.isWindows ? font.fullName : font.postscriptName
  const { ref, value: fontSize } = useObserverRefreshValue(masonryViewFontSize)
  const { ref: seenRef, seen } = useSeen()

  const members = fonts.byFamily[font.family].fonts
  const [isMoreVisible, setMoreVisible] = useState(false)
  const membersWithoutRepresentative = members.filter((m) => m.id !== font.id)
  const scheme = backgroundImage?.scheme ?? backgroundColor?.scheme
  const scape = backgroundImage ?? backgroundColor

  const viewMoreButton = (
    <div className="ml-6 pb-6 pt-4 min-h-[60px]">
      <ViewMore onClick={() => setMoreVisible((b) => !b)}>
        {isMoreVisible ? (
          'Hide styles'
        ) : (
          <>
            {membersWithoutRepresentative.length} style
            {membersWithoutRepresentative.length > 1 ? 's' : ''} available
          </>
        )}
      </ViewMore>
    </div>
  )

  const otherFonts = membersWithoutRepresentative.map((font) => {
    return (
      <OtherFont
        key={font.id}
        text={pangram}
        font={font}
        fontSize={fontSize}
        fontFamily={seen ? fontFamily : ''}
      />
    )
  })

  const bgImage = backgroundImage?.['grid-2']
  const [loaded, setLoaded] = useState<Record<string, boolean>>({})

  return (
    <div
      {...attrs(font)}
      className={classNames(
        'relative border-borderLight-500 dark:border-borderDark-500',
        styles.masonryViewItem,
        {
          [forceDarkModeClass]: scheme === 'dark',
        }
      )}
      style={{
        breakInside: 'avoid-column',
      }}
    >
      <div
        ref={applyRefs(seenRef, ref)}
        className="pt-6 controls-activator relative"
        style={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundColor: backgroundColor?.backgroundColor,
        }}
      >
        <AnimatePresence>
          {bgImage && (
            <picture key={bgImage.src}>
              <source type="image/webp" srcSet={bgImage.srcSet} />
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: !loaded[bgImage.src] ? 0 : 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'tween', duration: 0.3 }}
                onLoad={() =>
                  setLoaded((prev) => ({
                    ...prev,
                    [bgImage.src]: true,
                  }))
                }
                loading="lazy"
                src={bgImage.src}
                srcSet={bgImage.srcSet}
                className="object-cover object-center left-0 top-0 w-full h-full absolute border-0 -z-10"
              />
            </picture>
          )}
        </AnimatePresence>
        <Controls
          font={font}
          className={classNames('right-4 top-6 absolute')}
        />
        <FontName
          className={classNames(
            'px-6 min-h-[36px]',
            getTextClass(scape, { isOpaque: false })
          )}
        >
          {font.family}
        </FontName>
        <div style={{ fontFamily: config.backupFont }}>
          <div
            className={classNames('px-6 pb-6 w-full', getTextClass(scape))}
            style={{
              fontSize: fontSize,
              fontFamily: seen ? fontFamily : '',
              fontStyle: fontStyles?.fontStyle,
              lineHeight: 1.1,
              fontWeight: (fontStyles?.fontWeight ?? '').toString(),
            }}
          >
            {pangram}
          </div>
        </div>
        {membersWithoutRepresentative.length > 0 &&
          !isMoreVisible &&
          viewMoreButton}
      </div>
      <AnimatePresence>
        {isMoreVisible && (
          <motion.div
            transition={{ bounce: 0 }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            className="overflow-hidden"
          >
            {otherFonts}
          </motion.div>
        )}
      </AnimatePresence>

      {membersWithoutRepresentative.length > 0 &&
        isMoreVisible &&
        viewMoreButton}
      {isMoreVisible && (
        <div
          className={classNames(
            'border-b-4 dark:border-borderLight-500 border-borderDark-500'
          )}
        />
      )}
    </div>
  )
}

const OtherFont: FC<{
  font: FontDescriptor
  fontSize: string | number
  fontFamily: string
  text: string
}> = ({ font, fontSize, fontFamily, text }) => {
  const backgroundColor = useStore((state) =>
    getColorSchemeByFontId(state, font.id)
  )

  const backgroundImage = useStore((state) =>
    getBackgroundImageByFontId(state, font.id)
  )

  const scape = backgroundColor ?? backgroundImage

  return (
    <div
      // {...attrs(font)}
      style={{
        backgroundColor: backgroundColor?.backgroundColor,
        backgroundImage: `url(${backgroundImage?.['grid-2']})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      key={font.id}
      className="bg-white dark:bg-dark2-500 controls-activator nested py-6 first:border-t border-b border-borderLight-500 dark:border-borderDark-500 border-dashed relative"
    >
      <Controls
        font={font}
        className={classNames('right-4 top-6 absolute nested')}
      />
      <FontName
        className={classNames(
          'px-6 min-h-[36px]',
          getTextClass(scape, { isOpaque: false })
        )}
      >
        {font.fullName}
      </FontName>
      <div
        className={classNames('px-6 w-full', getTextClass(scape))}
        style={{
          fontFamily: config.backupFont,
          fontSize: fontSize,
          fontStyle: font.fontStyle ?? '',
          lineHeight: 1.1,
          fontWeight: (font?.fontWeight ?? '').toString(),
        }}
      >
        <FontWait
          style={{
            fontFamily,
          }}
        >
          {text}
        </FontWait>
      </div>
    </div>
  )
}
