import { fontStyles } from '@/data/fonts'
import remove from 'lodash/remove'
import { resolve } from 'path'

export interface FontData {
  family: string
  fullName: string
  postscriptName: string
  style: string
}

export interface FontDescriptor extends FontData {
  id: string
  fontWeight?: number | null
  fontStyle?: string | null
}

export const attrs = (_: FontDescriptor) => {
  return {
    // 'data-family': font.family,
    // 'data-fullname': font.fullName,
    // 'data-postscriptname': font.postscriptName,
    // 'data-style': font.style,
    // 'data-fontweight': fontStyles?.fontWeight,
    // 'data-fontstyle': fontStyles?.fontStyle,
  }
}

export const getFontStyle = (
  style: string
): { fontStyle: string; fontWeight: number | null } | null => {
  if (!Object.hasOwn(fontStyles, style)) {
    let s = ''
    let w: number | null = 400

    if (/italic/i.test(style)) s = 'italic'
    else if (/oblique/i.test(style)) s = 'oblique'

    // The order matters
    if (/book/i.test(style)) w = 400
    else if (/regular/i.test(style)) w = 400
    else if (/extra( |-)?light/i.test(style)) w = 200
    else if (/extra( |-)?thin/i.test(style)) w = 200
    else if (/light/i.test(style)) w = 200
    else if (/medium/i.test(style)) w = 500
    else if (/demi( |-)?bold/i.test(style)) w = 700
    else if (/ultra( |-)light/i.test(style)) w = 200
    else if (/extra( |-)light/i.test(style)) w = 200
    else if (/semi( |-)?bold/i.test(style)) w = 600
    else if (/extra( |-)?bold/i.test(style)) w = 950
    else if (/black/i.test(style)) w = 900
    else if (/bold/i.test(style)) w = 700

    return {
      fontStyle: s,
      fontWeight: w,
    }
  }

  return fontStyles[style as keyof typeof fontStyles]
}

export const getFontRepresentative = (
  fonts: FontDescriptor[]
): FontDescriptor => {
  const styles = fonts.map((font, i) => ({
    index: i,
    name: font.fullName,
    ...getFontStyle(font.style),
  }))

  const w400 = styles.find(
    (font) => font.fontStyle !== 'italic' && font.fontWeight === 400
  )

  const w400i = styles.find(
    (font) => font.fontStyle === 'italic' && font.fontWeight === 400
  )

  const condensed400 = styles.find(
    (font) =>
      font.fontStyle !== 'italic' &&
      font.fontWeight === 400 &&
      /condensed/i.test(font.name)
  )

  const condensed400i = styles.find(
    (font) =>
      font.fontStyle === 'italic' &&
      font.fontWeight === 400 &&
      /condensed/i.test(font.name)
  )

  const index =
    w400?.index ??
    w400i?.index ??
    condensed400?.index ??
    condensed400i?.index ??
    0

  return fonts[index]
}

export const getFontWeightFromStyle = (style: string) => {
  return fontStyles[style]?.fontWeight
}

export interface OrganizedFonts {
  all: FontDescriptor[]
  byFamily: {
    [key: string]: {
      representative: FontDescriptor
      fonts: FontDescriptor[]
    }
  }
}

export const organizeFonts = (fonts: FontDescriptor[]): OrganizedFonts => {
  const organized = fonts.reduce(
    (res, font) => {
      res.byFamily[font.family] = res.byFamily[font.family] ?? { fonts: [] }

      const family = res.byFamily[font.family]

      family.fonts.push(font)

      return res
    },
    {
      byFamily: {},
      all: [],
    } as OrganizedFonts
  )

  organized.all = []

  Object.keys(organized.byFamily).forEach((key) => {
    const family = organized.byFamily[key]

    family.fonts.sort((a, b) => {
      const x = getFontWeightFromStyle(a.style) ?? 1000
      const y = getFontWeightFromStyle(b.style) ?? 1000
      return x - y
    })

    family.representative = getFontRepresentative(family.fonts)

    const rounded = remove(family.fonts, (font) =>
      /rounded/i.test(font.fullName)
    )

    const condensed = remove(family.fonts, (font) =>
      /condensed/i.test(font.fullName)
    )

    const display = remove(family.fonts, (font) =>
      /condensed/i.test(font.fullName)
    )

    family.fonts = family.fonts.concat(condensed)
    family.fonts = family.fonts.concat(rounded)
    family.fonts = family.fonts.concat(display)

    organized.all = organized.all.concat(organized.byFamily[key].fonts)
  })

  return organized
}

export interface AlphabeticOrganizedFonts {
  [key: string]: FontDescriptor[]
}

export type ColumnizedAlphabeticFonts = Record<string, FontDescriptor[][]>

export const toColumnizedAlphabetic = (
  alphabetic: AlphabeticOrganizedFonts
): ColumnizedAlphabeticFonts => {
  return Object.entries(alphabetic).reduce((r, [letter, values]) => {
    r[letter] = stack(2, values)
    return r
  }, {} as ColumnizedAlphabeticFonts)
}

export const toAlphabetic = (fonts: FontDescriptor[]) => {
  const result: Record<string, FontDescriptor[]> = {}

  fonts.forEach((font) => {
    const char = (font.fullName ?? font.family).toLowerCase()[0]
    const arr = (result[char] = result[char] ?? ([] as FontDescriptor[]))

    arr.push(font)
  })

  return Object.fromEntries(
    Object.entries(result).sort(([a], [b]) => a.localeCompare(b))
  )
}

export function stack<T = any>(columns: number, items: T[]): T[][] {
  const stacks: T[][] = []

  for (let i = 0; i < columns; i++) {
    stacks.push([])
  }

  for (let i = 0; i < items.length; i++) {
    const column = i % columns
    stacks[column].push(items[i])
  }

  return stacks
}

export const isIgnoredFont = (name: string) => {
  return /(Apple Braille|al nile|al bayan|al tarikh|STIXSize)/i.test(name)
}

export const preloadImage = async (
  src: string,
  srcSet?: string,
  sources: Array<{
    type: string
    srcSet: string
  }> = []
): Promise<any> => {
  const picture = document.createElement('picture')
  const image = document.createElement('img')

  picture.appendChild(image)

  sources.forEach(({ type, srcSet }) => {
    const el = document.createElement('source')

    el.type = type
    el.srcset = srcSet
    picture.prepend(el)
  })

  return new Promise<{ currentSrc?: string }>((resolve, reject) => {
    image.onload = () => {
      resolve(image)
    }

    image.onerror = () => reject()

    image.src = src
    if (srcSet) image.srcset = srcSet
  })
}

export const getImageFrameUrl = (index: number) => `/landing-image/${index}.jpg`
