import { Bus, Events } from '@/events'
import { useStore } from '@/store'
import { useHotkeys } from 'react-hotkeys-hook'

export const usePageHotkey = () => {
  const actions = useStore(
    ({
      changeGridView,
      toggleListOrder,
      toggleDarkMode,
      increaseFontSize,
      decreaseFontSize,
    }) => ({
      changeGridView,
      decreaseFontSize,
      increaseFontSize,
      toggleDarkMode,
      toggleListOrder,
    })
  )

  useHotkeys('0', () => Bus.emit(Events.OpenPangram))
  useHotkeys('1', () => actions.toggleDarkMode())
  useHotkeys('2', () => actions.toggleListOrder())
  useHotkeys('3', () => actions.changeGridView())

  useHotkeys('=,+,shift+=,num_add', () => actions.increaseFontSize())

  useHotkeys('-,shift+-,num_subtract', () => actions.decreaseFontSize())
}
