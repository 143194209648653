import { useDeferUntilObserved } from '@/hooks/use-defer-until-observed'
import { FC } from 'react'

export const FontWait: FC<JSX.IntrinsicElements['div']> = ({
  style,
  children,
  ...props
}) => {
  const { fontFamily, ...styles } = style ?? {}

  const { ref, value: deferredValue } = useDeferUntilObserved(
    undefined,
    fontFamily
  )

  return (
    <div
      ref={ref}
      {...props}
      style={{
        ...styles,
        fontFamily: deferredValue,
      }}
    >
      {fontFamily != null && children}
    </div>
  )
}
