import {
  BackgroundScheme,
  backgroundSchemeById,
  ColorScheme,
  colorSchemeById,
} from '@/data/colors'
import { State } from '.'

export const getColorSchemeByFontId = (
  state: State,
  fontId: string
): ColorScheme | undefined => {
  const id = state.fontStatuses[fontId]?.colorSchemeId

  return id == null ? undefined : colorSchemeById[id]
}

export const getBackgroundImageByFontId = (
  state: State,
  fontId: string
): BackgroundScheme | undefined => {
  const id = state.fontStatuses[fontId]?.backgroundSchemeId

  return id == null ? undefined : backgroundSchemeById[id]
}
