import classNames from 'classnames'
import React, { FC } from 'react'

export const Letter: FC<JSX.IntrinsicElements['div']> = ({ ...props }) => {
  return (
    <div
      {...props}
      className={classNames(
        'text-[32px] xl:text-[50px] font-bold text-black dark:text-white leading-none',
        props.className
      )}
    >
      {props.children}
    </div>
  )
  // return <div className="text-[50px] pl-6 mt-12 font-bold text-black dark:text-white">{children}</div>
}
