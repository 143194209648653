import React, { useMemo } from 'react'
import uniqueId from 'lodash/uniqueId'
import { SVGProps } from 'react'

const BackgroundImageToggleHover = ({
  scheme,
  ...props
}: SVGProps<SVGSVGElement> & { scheme: 'light' | 'dark' }) => {
  const maskId = useMemo(() => `bg-image-cycle-hover-${uniqueId()}`, [])

  const rectFillClass = scheme
    ? scheme === 'light'
      ? 'fill-white'
      : 'fill-dark1-500'
    : 'fill-white dark:fill-dark1-500'

  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={11}
        cy={11}
        r={10}
        className={
          scheme
            ? scheme === 'light'
              ? 'fill-dark1-500 stroke-dark1-500'
              : 'fill-white stroke-white'
            : 'fill-dark1-500 stroke-dark1-500 dark:fill-white dark:stroke-dark1-500'
        }
        // fill="#1F1C25"
        // stroke="#1F1C25"
        strokeWidth={2}
      />
      <mask
        id={maskId}
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={4}
        y={4}
        width={14}
        height={14}
      >
        <circle
          cx={11}
          cy={11}
          r={6}
          className={
            scheme
              ? scheme === 'light'
                ? 'fill-dark1-500'
                : 'fill-white'
              : 'fill-dark1-500 dark:fill-white'
          }
          // fill="white"
          // stroke="#1F1C25"
          strokeWidth={2}
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect
          opacity={0.9}
          x={1}
          y={1}
          width={10}
          height={10}
          fill="white"
          className={rectFillClass}
        />
        <rect
          opacity={0.1}
          x={1}
          y={11}
          width={10}
          height={10}
          fill="white"
          className={rectFillClass}
        />
        <rect
          opacity={0.6}
          x={11}
          y={1}
          width={10}
          height={10}
          fill="white"
          className={rectFillClass}
        />
        <rect
          opacity={0.3}
          x={11}
          y={11}
          width={10}
          height={10}
          fill="white"
          className={rectFillClass}
        />
      </g>
    </svg>
  )
}

export default BackgroundImageToggleHover
