import React, { FC, useMemo } from 'react'
import shallow from 'zustand/shallow'
import classNames from 'classnames'
import { LetterGridItem } from '@/components/layouts/LetterView/LetterGridItem'
import { Letter } from '@/components/common'
import { toAlphabetic } from '@/utils'
import { useStore } from '@/store'
import { useScrollToLetter } from '@/hooks/use-scroll-to-letter'
import { letterSectionAttribute } from '@/data/classes'
import styles from '@/components/layouts/LetterView/LetterGridItem.module.css'
import { tw } from '@/tailwind'
import { usePageHotkey } from '@/hooks/use-page-hotkey'

const gridClass = tw`grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7`

export const LetterViewLayout: FC = () => {
  const { fonts, listOrder, fontsRandomized } = useStore(
    ({ fonts, listOrder, fontsRandomized }) => ({
      fonts,
      listOrder,
      fontsRandomized,
    }),
    shallow
  )

  const { portal } = useScrollToLetter()

  const allFonts = useMemo(() => {
    let result

    if (listOrder === 'random') {
      result = fontsRandomized.map((index) => fonts.all[index])
    } else {
      result = Object.values(fonts.byFamily).map(
        (family) => family.representative
      )
    }

    return result
  }, [listOrder, fonts, fontsRandomized])

  const alphabetic = useMemo(() => toAlphabetic(allFonts), [allFonts])

  usePageHotkey()

  if (listOrder === 'random') {
    return (
      <div className="mx-auto">
        {portal}
        <Letter className="pb-4 pt-3 px-6 left-0 bg-white dark:bg-dark2-500 z-30">
          Random order
        </Letter>
        <div className="px-6">
          <div className={classNames(styles.grid, gridClass)}>
            {allFonts.map((font) => {
              return <LetterGridItem key={font.id} font={font} />
            })}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {portal}
      <div>
        {Object.entries(alphabetic).map(([letter, fonts]) => {
          if (fonts.length === 0) return null
          return (
            <div key={letter}>
              <div
                {...{ [letterSectionAttribute]: letter.toLowerCase() }}
                className="-top-navH relative h-[1px]"
              />
              <Letter className="pb-4 pt-3 px-6 sticky w-full top-navH left-0 bg-white dark:bg-dark2-500 z-30">
                {letter.toUpperCase()}
              </Letter>
              <div className={classNames(styles.grid, gridClass, 'px-6')}>
                {fonts.map((font) => {
                  return <LetterGridItem key={font.id} font={font} />
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
