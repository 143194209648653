export enum GridOption {
  single = 'single',
  masonry = 'masonry',
  square = 'square',
}

export const gridViewList = [
  GridOption.single,
  GridOption.masonry,
  GridOption.square,
]

export const defaultGridView = GridOption.single
