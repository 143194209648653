import { useEffect, useState } from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'

export const useSeen = (options?: Omit<IntersectionOptions, 'onChange'>) => {
  const [seen, setSeen] = useState(false)
  const { ref } = useInView({
    rootMargin: '300px 0px',
    ...options,
    triggerOnce: true,
    onChange(inView) {
      if (inView) setSeen(true)
    },
  })

  return {
    ref,
    seen,
  }
}

export const useObserverRefreshValue = <Value>(
  value: Value,
  options: Omit<IntersectionOptions, 'onChange'> = {}
) => {
  const { ref, inView, entry } = useInView({
    rootMargin: '300px 0px',
    ...options,
    onChange(inView) {
      if (inView) setDeferredFontSize(value)
    },
  })

  const [deferredValue, setDeferredFontSize] = useState(value)

  useEffect(() => {
    if (inView) setDeferredFontSize(value)
  }, [value, setDeferredFontSize, inView])

  return {
    ref,
    inView,
    entry,
    value: deferredValue,
  }
}

export const useDeferUntilObserved = <InitialValue, Value>(
  initialValue: InitialValue,
  value: Value,
  options: Omit<IntersectionOptions, 'onChange'> = {}
) => {
  const [seen, setSeen] = useState(false)

  const { ref, inView, entry } = useInView({
    rootMargin: '1000px 0px',
    ...options,
    triggerOnce: true,
    onChange(inView) {
      if (inView) setSeen(true)
    },
  })

  return {
    ref,
    inView,
    entry,
    value: seen ? value : initialValue,
  }
}
