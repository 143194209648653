import * as React from 'react'
import { SVGProps } from 'react'

const GridSingle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="22px"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="2"
      width="18"
      height="18"
      rx="1"
      // stroke="#1F1C25"
      className="stroke-dark1-500 dark:stroke-white"
      strokeWidth="2"
    />
    <path
      d="M2.25 8L19.75 8"
      className="stroke-dark1-500 dark:stroke-white"
      // stroke="#1F1C25"
      strokeWidth="2"
    />
    <path
      d="M2.25 14L19.75 14"
      className="stroke-dark1-500 dark:stroke-white"
      // stroke="#1F1C25"
      strokeWidth="2"
    />
    <rect
      x="3"
      y="3"
      width="16"
      height="4"
      // fill="#1F1C25"
      className="fill-dark1-500 dark:fill-white"
    />
  </svg>
  // <svg
  //   viewBox="0 0 22 22"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <rect
  //     x={2}
  //     y={2}
  //     width={18}
  //     height={18}
  //     rx={1}
  //     // stroke="#1F1C25"
  //     // className="stroke-dark1-500 dark:stroke-white"
  //     className="fill-white dark:fill-white dark:stroke-white"
  //     strokeWidth={2}
  //   />
  //   <path
  //     d="M2.25 8h17.5M2.25 14h17.5"
  //     stroke="#1F1C25"
  //     strokeWidth={2}
  //     // className="stroke-dark1-500 dark:stroke-white "
  //     className="stroke-dark1-500 dark:stroke-white"
  //   />
  //   <path
  //     fill="#1F1C25"
  //     d="M3 3h16v4H3z"
  //     className="stroke-dark1-500 dark:stroke-white"
  //     // className="stroke-dark1-500 dark:stroke-white"
  //   />
  // </svg>
)

export default GridSingle
