import * as React from 'react'
import { SVGProps } from 'react'

const GridSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={18}
      height={18}
      rx={1}
      // stroke="#1F1C25"
      className="stroke-dark1-500 dark:stroke-white"
      strokeWidth={2}
    />
    <path
      d="M7 1v17.5M13 1v17.5M1.25 7h17.5M1.25 13h17.5"
      // stroke="#1F1C25"
      className="stroke-dark1-500 dark:stroke-white"
      strokeWidth={2}
    />
    <path
      // fill="#1F1C25"
      d="M2 2h4v4H2z"
      className="fill-dark1-500 dark:fill-white"
    />
  </svg>
)

export default GridSquare
