import * as React from 'react'
import { SVGProps } from 'react'

const DarkmodeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={11}
      cy={11}
      r={10}
      fill="#fff"
      stroke="#1F1C25"
      strokeWidth={2}
    />
    <path
      d="M18.778 3.222c-4.296-4.296-11.26-4.296-15.556 0-4.296 4.296-4.296 11.26 0 15.556L18.778 3.222Z"
      fill="#1F1C25"
    />
  </svg>
)

export default DarkmodeOff
