import React from 'react'
import { SVGProps } from 'react'

const BackgroundColorToggle = ({
  scheme,
  ...props
}: SVGProps<SVGSVGElement> & { scheme?: 'dark' | 'light' }) => {
  return (
    <svg
      className="hover:rotate-0 transform origin-center rotate-180"
      width={20}
      height={20}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={11}
        cy={11}
        r={10}
        className={
          // ''
          scheme
            ? scheme === 'light'
              ? 'fill-white stroke-dark1-500 '
              : 'transform rotate-180 origin-center fill-dark1-500 stroke-white'
            : 'fill-white stroke-dark1-500 dark:fill-dark1-500 dark:stroke-white'
        }
        // fill="#1F1C25"
        // stroke="#fff"
        // fill="#fff"
        // stroke="#1F1C25"
        strokeWidth={2}
      />
      <path
        d="M3 18.556c4.296 4.296 11.26 4.296 15.556 0 4.296-4.295 4.296-11.26 0-15.556L3 18.556Z"
        className={
          // ''
          scheme
            ? scheme === 'light'
              ? 'fill-dark1-500'
              : 'fill-white'
            : 'dark:fill-white fill-dark1-500'
        }
        // fill="#fff"
        // fill="#1F1C25"
      />
    </svg>
  )
}

export default BackgroundColorToggle
