import React from 'react'
import { SVGProps } from 'react'

const BackgroundRemove = ({
  scheme,
  ...props
}: SVGProps<SVGSVGElement> & { scheme?: 'dark' | 'light' }) => {
  let className = 'stroke-black dark:stroke-white'

  if (scheme) {
    if (scheme === 'light') {
      className = 'stroke-black'
    } else {
      className = 'stroke-white'
    }
  }

  return (
    <svg
      data-meme={scheme}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        opacity="0.11"
        cx="11"
        cy="11"
        r="10"
        // stroke="white"
        strokeWidth="2"
        className={className}
      />
      <path
        d="M8 14L14 8"
        // stroke="white"
        strokeWidth="2"
        className={className}
      />
      <path
        d="M8 8L14 14"
        // stroke="white"
        strokeWidth="2"
        className={className}
      />
    </svg>
  )
}

export default BackgroundRemove
